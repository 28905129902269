import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { Heading } from "@chakra-ui/react"
import Layout from "../components/layout"
import MailSent from "../img/mailsent.svg"
import { FormattedMessage } from "gatsby-plugin-intl"
import SEO from "../components/seo"

const Success = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle} container>
      <div className="sc-mailsent-wrapper">
        <SEO title="Melding mottatt" />
        <div className="sc-mailsent">
          <img src={MailSent} />
          <Heading marginTop="10">
            <FormattedMessage id="signupSuccess" />
          </Heading>
        </div>
      </div>
    </Layout>
  )
}

Success.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
}

export default Success

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
